<template>
    <div>
        <div v-if="isShowTabs" class="">
            <div class="nav-pill__wrapper mb-main">
                <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tabs" :key="index" @click="selectTabs(o)">{{o.name}}</div>
            </div>
        </div>

        <template v-if="isWorldCup">
            <div class="display-flex-align-center">
                <div class="icon-league mr-05rem mb-05rem home-match__main-title-flag">
                    <img :src="`${s3ImgUrl}/football/up/league/world_cup_2022_logo.png`" class="img-contain w-100 h-100" >
                </div>
                <div class="match-table__main-group-stage-title"><span v-if="!isMobile">International | </span>FIFA World Cup 2022 &trade;<div class="home-match__country" v-if="isMobile">International</div></div>
            </div>
            <div class="match-table__main-group-stage-title">Group Stage</div>
        </template>

        <template v-if="!isWorldCup">
            <div class="display-flex-align-center">
                <div class="icon-league mr-05rem mb-05rem home-match__main-title-flag">
                    <img :src="`${s3ImgUrl}/${leagueInfo.logo}`" class="img-contain w-100 h-100" >
                </div>
                <div class="match-table__main-group-stage-title"><span v-if="!isMobile">{{ leagueInfo.country }} | </span>{{ matchTableName }}<div class="home-match__country" v-if="isMobile">{{ leagueInfo.country }}</div></div>
            </div>
            <div class="match-table__main-group-stage-title">{{ $t("SEASON") }} 2023-24</div>
        </template>
        <div v-show="!isLoading">
            <div class="match-table__wrapper" v-for="(o, index1) in matchTableModified" :key="index1">
                <template v-if="isWorldCup">
                    <div class="match-table__header">{{o.tableName.split("-")[1]}}</div>
                    <div class="match-table" v-if="!isMobile">
                        <div  class="display-flex">
                            <!-- <div class="match-table__index">#</div> -->
                            <div class="match-table__club">              
                                <div class="uppercase">{{$t('TEAM')}}</div>
                            </div>
                        </div>
                        <div class="match-table__score">
                            <div>MP</div>
                            <div>W</div>
                            <div>D</div>
                            <div>L</div>
                            <div>F</div>
                            <div>A</div>
                            <div>+-</div>
                            <div>P</div>
                        </div>   
                    </div>
                </template>

                <template v-if="!isWorldCup && !isMobile">
                    <div class="match-table__header flex-sb" >
                        <div  class="display-flex">
                            <!-- <div class="match-table__index">#</div> -->
                            <div class="match-table__club">              
                                <div class="uppercase">{{$t('TEAM')}}</div>
                            </div>
                            <div class="match-table__index"></div>
                        </div>
                        <div class="match-table__score match-table__score-with-last-5">
                            <div>MP</div>
                            <div>W</div>
                            <div>D</div>
                            <div>L</div>
                            <div>F</div>
                            <div>A</div>
                            <div>+-</div>
                            <div>P</div>
                            <div class="last-5-container uppercase">{{ $t("LAST_5") }}</div>
                        </div>   
                    </div>
                </template>
                <template v-if="!isMobile">
                    <!-- <Navigation v-for="(o, index) in o.total" :key="index" :id="o.teamId" :category="'team'" :name="o.teamName"  class="match-table"> -->
                    <div class="match-table"
                        v-for="(o, index2) in o.total"
                        :key="index2"
                        :class="{ 'hovered': (index2 === currentRowIndex && index1 === componentIndex) }"
                        @mouseover="highlightMobileRow(index1, index2)"
                        @mouseleave="highlightMobileRow(index1, index2, false)"
                        @touchstart="highlightMobileRow(index1, index2)"
                    >
                        <div class="display-flex-center">
                            <div class="match-table__index" :class="[o.promotionColor, isWorldCup ? 'color-1' : '']">{{o.qualified}}</div>
                            <div class="match-table__club">                   
                                <div class="match-table__club-icon ">
                                    <Icon  :logoUrl=o.logo ></Icon>
                                    </div>
                                <div>{{o.teamName}}</div>
                            </div>
                        </div>
                        <div class="match-table__score" :class="{ 'match-table__score-with-last-5' : !isWorldCup }">
                            <div>{{o.gamesPlayed === null? '-' : o.gamesPlayed}}</div>
                            <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                            <div>{{o.gamesDrawn === null? '-' : o.gamesDrawn}}</div>
                            <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                            <div>{{o.gamesScored === null? '-'  :o.gamesScored}}</div>
                            <div>{{o.gamesAgainst === null? '-' : o.gamesAgainst}}</div>
                            <div>{{o.goalsDifference === null? '-' : o.goalsDifference}}</div>
                            <div>{{o.points === null? '-' : o.points}}</div>

                            <div class="display-flex display-flex-se last-5-container" v-if="!isWorldCup">
                                <div :class="o.recentSixthResult"></div>
                                <div :class="o.recentFifthResult"></div>
                                <div :class="o.recentFourthResult"></div>
                                <div :class="o.recentThirdResult"></div>
                                <div :class="o.recentSecondResult"></div>
                                <!-- <div :class="o.recentFirstResult"></div> -->
                            </div>
                            
                            <!-- <div class="ranking-indicator-wrapper" v-if="index2 === 0 && !isMobile">
                                <div class="ranking-indicator">
                                    <div class="icon-indicator won-indicator"></div>
                                    <div>{{$t("WON")}}</div>
                                </div>
                                <div class="ranking-indicator">
                                    <div class="icon-indicator lost-indicator"></div>
                                    <div>{{$t("LOST")}}</div>
                                </div>
                                <div class="ranking-indicator">
                                    <div class="icon-indicator drawn-indicator"></div>
                                    <div>{{$t("DRAWN")}}</div>
                                </div>
                            </div> -->
                        </div> 

                        <!-- <div class="ranking-indicator-wrapper" v-if="index2 === 0 && isMobile">
                            <div class="ranking-indicator-cue1">
                                <div>{{$t("WON_SF")}}: {{$t("WON")}}</div>
                                <div>{{$t("DRAW_SF")}}: {{$t("DRAWN")}}</div>
                                <div>{{$t("LOSS_SF")}}: {{$t("LOST")}}</div>
                                <div>{{$t("GOAL_DIFFERENCE_SF")}}: {{$t("GOAL_DIFFERENCE")}}</div>
                                <div>{{$t("POINTS_SF")}}: {{$t("POINTS")}}</div>
                            </div>
                            <div class="ranking-indicator-cue2">
                                <div class="ranking-indicator">
                                    <div class="icon-indicator small-won-indicator"></div>
                                    <div>{{$t("WON")}}</div>
                                </div>
                                <div class="ranking-indicator">
                                    <div class="icon-indicator small-lost-indicator"></div>
                                    <div>{{$t("LOST")}}</div>
                                </div>
                                <div class="ranking-indicator">
                                    <div class="icon-indicator small-drawn-indicator"></div>
                                    <div>{{$t("DRAWN")}}</div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- </Navigation> -->
                </template>

                <!-- !isWorldCup &&  -->
                <template v-if="isMobile">
                    <div>
                        <div class="match-table__split-column-wrapper">
                            <div class="match-table__left-side">
                                <div class="match-table__left-header display-flex">
                                    <!-- <div class="match-table__index">#</div> -->
                                    <div class="match-table__club">              
                                        <div class="uppercase">{{$t('TEAM')}}</div>
                                    </div>
                                    <div class="match-table__index"></div>
                                </div>

                                <div class="match-table"
                                    v-for="(o, index2) in o.total"
                                    :key="index2"
                                    :class="{ 'hovered': (index2 === currentRowIndex && index1 === componentIndex) }"
                                    @mouseover="highlightMobileRow(index1, index2)"
                                    @mouseleave="highlightMobileRow(index1, index2, false)"
                                    @touchstart="highlightMobileRow(index1, index2)"
                                >
                                    <div class="display-flex-center">
                                        <div class="match-table__index" :class="[o.promotionColor, isWorldCup ? 'color-1' : '']">{{o.qualified}}</div>
                                        <div class="match-table__club">                   
                                            <div class="match-table__club-icon ">
                                                <Icon  :logoUrl=o.logo ></Icon>
                                                </div>
                                            <div>{{o.teamName}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="match-table__right-side">
                                <div class="match-table__right-header match-table__score" :class="[isWorldCup ? 'match-table__wc-score' : 'match-table__score-with-last-5']">
                                    <div>MP</div>
                                    <div>W</div>
                                    <div>D</div>
                                    <div>L</div>
                                    <div>F</div>
                                    <div>A</div>
                                    <div>+-</div>
                                    <div>P</div>
                                    <div v-if="!isWorldCup" class="last-5-container uppercase">{{ $t("LAST_5") }}</div>
                                </div>
                                
                                <div class="match-table"
                                :class="[isWorldCup ? 'match-table__wc-score' : 'match-table__score-with-last-5', {'hovered': (index2 === currentRowIndex && index1 === componentIndex) }]"
                                    v-for="(o, index2) in o.total"
                                    :key="index2"
                                    @mouseover="highlightMobileRow(index1, index2)"
                                    @mouseleave="highlightMobileRow(index1, index2, false)"
                                    @touchstart="highlightMobileRow(index1, index2)"
                                >
                                    <div class="match-table__score" :class="{ 'match-table__score-with-last-5' : !isWorldCup }">
                                        <div>{{o.gamesPlayed === null? '-' : o.gamesPlayed}}</div>
                                        <div>{{o.gamesWon === null? '-' : o.gamesWon}}</div>
                                        <div>{{o.gamesDrawn === null? '-' : o.gamesDrawn}}</div>
                                        <div>{{o.gamesLost === null? '-' : o.gamesLost}}</div>
                                        <div>{{o.gamesScored === null? '-'  :o.gamesScored}}</div>
                                        <div>{{o.gamesAgainst === null? '-' : o.gamesAgainst}}</div>
                                        <div>{{o.goalsDifference === null? '-' : o.goalsDifference}}</div>
                                        <div>{{o.points === null? '-' : o.points}}</div>

                                        <div class="display-flex display-flex-se last-5-container" v-if="!isWorldCup">
                                            <div :class="o.recentSixthResult"></div>
                                            <div :class="o.recentFifthResult"></div>
                                            <div :class="o.recentFourthResult"></div>
                                            <div :class="o.recentThirdResult"></div>
                                            <div :class="o.recentSecondResult"></div>
                                            <!-- <div :class="o.recentFirstResult"></div> -->
                                        </div>
                                        
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div class="no-data" v-show="isShowNoData">{{$t("NO_TABLE")}}</div>

            </div>

            <template v-if="isWorldCup">
                <div class="match-table__tips-wrapper">
                    <div class="match-table__tips" >
                        <div class="match-table__index color-1">#</div>
                        <div>{{ $t('ADVANCE_TO_ROUND_OF_16') }} </div>
                    </div>
                </div>
            </template>

            <template v-if="!isWorldCup">
                <div class="match-table__tips-wrapper" v-for="(o, index) in promotionList" :key="index">
                    <div class="match-table__tips" >
                        <div class="match-table__index" :class="o.color">#</div>
                        <div>{{o.value}}</div>
                    </div>
                </div>
            </template>
        </div>
        <Loading v-show="isLoading"></Loading>

    </div>
  
</template>

<script>

import Loading from '@/components/indicator/Loading.vue'
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'
export default {
    components:{
        Loading
    },
    props: {
        matchTableListData: {
            type: Array
        },
        matchTableTipsList: {
            type: Array
        },
        isShowTabs: {
            type: Boolean,
            default:true
        },
        type: {
            type: String,
             default:'total'
        },
        params:{
            type: String,
        },
        leagueId:{
            type: String,
        },
        leagueInfo: {
            type: Object,
        },
    },
 	computed: {
			...mapGetters([
                'isMobile',
                'matchDetailScore',
                'currentLocale',
                'currentSportType',
                'isWorldCup',
			]),
            matchTableModified() {
                this.matchTableList.forEach(group => {
                    group.total.forEach((team, i) => {
                        if (this.isWorldCup) {
                            team.qualified = (i == 0 || i == 1) ? "#" : "";
                        } else {
                            team.qualified = "#";
                            // team.recentFirstResult = this.assignMatchOutcome(team.recentFirstResult);
                            team.recentSecondResult = this.assignMatchOutcome(team.recentSecondResult);
                            team.recentThirdResult = this.assignMatchOutcome(team.recentThirdResult);
                            team.recentFourthResult = this.assignMatchOutcome(team.recentFourthResult);
                            team.recentFifthResult = this.assignMatchOutcome(team.recentFifthResult);
                            team.recentSixthResult = this.assignMatchOutcome(team.recentSixthResult);
                        }
                    });
                });

                return this.matchTableList;
            }
		},
    data() {
        return {
                s3ImgUrl:config.s3ImgUrl,
                isLoading:false,
                isShowNoData:false,
                tabs:[
                    {
                        name:'All',
                        id:'',
                        selected:true,
                    },
                    {
                        name:'Home',
                        id:'1',
                        selected:false,
                    },
                    {
                        name:'Away',
                        id:'2',
                        selected:false,
                    },
                ],
                matchTableName: "",
                matchTableList:[],           
                promotionList: [],    
                currentRowIndex: null,
                currentComponentIndex: null,
            }
    },

        watch:{
            params:{
                deep:true,
                handler(newVal,oldVal) {//pass param and get data from api here
                    this.getSoccerLeagueRankingData(newVal);
                }
            },
              matchTableListData:{// direct pass data to render
                deep:true,
                handler(newVal,oldVal) {  
                    this.matchTableList = newVal;//newVal[this.type]
                    this.matchTableName = newVal[0].tableName
                    this.promotionList =  newVal[0].promotionList;
                }
            }
        },

    mounted() {
       // debugger;
     //  this.matchTableList =  this.matchTableListData;
        //this.getSoccerLeagueRankingData();
    },
   
    methods: {
        	...mapActions([
				"getSoccerLeagueRanking",
                "getMatchDetailScore",
                "getMatchDetailScoreAsync",
			]),

            // 0：胜
            // 1：平
            // 2：负
            // 3：空
            assignMatchOutcome(data) {
                if (data == 0) {
                    return 'small-won-indicator';
                } else if (data == 1) {
                    return 'small-drawn-indicator';
                } else if (data == 2) {
                    return 'small-lost-indicator';
                } else if (data == 3) {
                    return 'small-empty-indicator';
                }
            },

            async getSoccerLeagueRankingData(leagueIdList){
          
                let params = {
                    leagueIdList : leagueIdList
                }
                this.isLoading = true;
              
                const result = await this.getSoccerLeagueRanking(params);
                this.matchTableList = result.result;

                if (this.matchTableList.length === 0) {
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                }

                this.isLoading = false;
            //    if (typeof this.matchDetailScore.leagueId === "undefined") {
                  
            //           let matchId = this.$route.params.matchId

            //        const results = await this.getMatchDetailScoreAsync({
            //             matchId:matchId,
            //             timeZone:this.$tools.getCurrentTimeZone()
            //         })
                   
            //           let params = {
            //             leagueId : results.result.leagueId
            //         }
                    
            //          const result = await this.getSoccerLeagueRanking(params);
            //         this.matchTableList = result.result;
            //    } else {
            //          let params = {
            //             leagueId : this.matchDetailScore.leagueId
            //         }
                    
            //          const result = await this.getSoccerLeagueRanking(params);
            //         this.matchTableList = result.result;
            //    }
              
               
              
            },

            prevBtnMenuLeagueTableListClick(){
                this.getSoccerLeagueRanking({
                    "leagueId":''
                }).then(res => {
                this.matchTableList =  res.result;
                }).catch(res => {
                    debugger;
                // this.$store.dispatch('VerifiFailure', res);
                })
            },
            selectTabs(o) {
                this.tabs.forEach(x=>{
                    x.selected = false
                });
                o.selected = true;
                    this.isLoading=true;
                // this.matchObj=[];
                    setTimeout(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })       
                    }      
                    , 800);
            },
            highlightMobileRow(componentIndex, index, highlight = true) {
                this.componentIndex = componentIndex;
                if (highlight)
                    this.currentRowIndex = index;
                else
                    this.currentRowIndex = null;
            },
    }
}
</script>
<style scoped>
    .match-table__main-group-stage-title {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    .match-table__wrapper {
        /* margin-bottom: 1.5rem; */
    }
    .match-table__header-country__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .88rem;
        margin-bottom: .4rem;
    }
    .match-table__header{        
        background-color: #212B30;
        font-weight: 700;
        font-size: 0.875rem;
        padding: 0.875rem 0.75rem;
    }
    .flex-sb {
        display: flex;
        justify-content: space-between;
    }
    .match-table {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color:#CFD9DE;
        /* border-bottom:1px solid #212B30; */
        padding: .5rem 0.8rem;
        font-size: .6875rem;
        position: relative;
    }
    .match-table::after {
        content: '';
        width: calc(100% - 1.6rem);
        border-bottom: 1px solid #212B30;
        position: absolute;
        bottom: 0;
    }
    .match-table:last-of-type::after {
        border-bottom: none;
    }
    .match-table.selected{
        background-color: #212B30;
        color: white;
        font-weight: 700;
    }
    /* .match-table:hover, */
    .match-table.hovered {
      background-color: var(--bg-hover);  
    }
    /* .match-table:hover::after */
    .match-table.hovered::after {
        border-bottom: 1px solid var(--bg-hover);
    }
    .match-table__index{
        margin-right: .3rem;
        width: 1.1rem;
    }
    .color-1{
        color:#5CD66C;
    }
    .color-3{
        color:#f7eb83;
    }
    .color-2{
        color:#2E8FEF;
    }
    .color-4{
        color:#ffc0cb;
    }
    .color-r{
        color:#CF283E;
    }
    .match-table__club{
        display:flex;
        align-items: center;
        flex:2;
        min-width: 9.313rem;
        max-width: 9.313rem;/*8rem*/
    }

    .match-table__club-icon{
        margin-right: .5rem;
    }
    .match-table__score{
        display:flex;
        width:10rem;
        justify-content: space-between;
        text-align: center;
    }
    .match-table__score-with-last-5 {
        width: 28rem;
    }
    .last-5-container {
        flex: 2.2 !important;
    }
    .match-table__score>div{
        flex:1;
        margin: 0 .25rem;
    
    }
    .match-table__tips-wrapper{
        background-color: var(--color-theme);
        color: #CFD9DE;
    }
    .match-table__tips{
        display: flex;
        flex-direction: row;
        margin: 0 .8rem;
        padding: .5rem 0;
        font-size: .7rem;
    }

    .ranking-indicator-wrapper {
        display: flex;
    }
    .ranking-indicator-cue1 {
        display: flex;
        flex-wrap: wrap;
        color: #637181;
        font-size: .625rem;
        font-weight: 400;
        padding-right: .875rem;
        border-right: 1px solid #ECECEC;
    }
    .ranking-indicator-cue1>div {
        margin-right: .625rem;
        line-height: 1rem;
    }
    .ranking-indicator-cue2 {
        display: flex;
        padding-left: .875rem;
    }
    .ranking-indicator {
        display: flex;
        color: #637181;
        font-weight: 400;
        font-size: 1rem;
    }
    .ranking-indicator:not(:last-of-type) {
        margin-right: 1rem;
    }
    .icon-indicator {
        margin-right: 0.3rem;
    }
    /* .won-indicator {
        background: url('../../../static/images/icons/icon_rankings_css_sprites.png') -5px -35px no-repeat;
        width: 20px;
        height: 20px;
    }
    .drawn-indicator {
        background: url('../../../static/images/icons/icon_rankings_css_sprites.png') -5px -5px no-repeat;
        width: 21px;
        height: 20px;
    }
    .lost-indicator {
        background: url('../../../static/images/icons/icon_rankings_css_sprites.png') -36px -5px no-repeat;
        width: 20px;
        height: 20px;
    } */
    .small-won-indicator {
        background: url('../../../static/images/icons/icon_small_rankings_css_sprites.png') -31px -5px no-repeat;
        width: 16px;
        height: 16px;
    }
    .small-drawn-indicator {
        background: url('../../../static/images/icons/icon_small_rankings_css_sprites.png') -5px -5px no-repeat;
        width: 16px;
        height: 16px;
    }
    .small-lost-indicator {
        background: url('../../../static/images/icons/icon_small_rankings_css_sprites.png') -5px -31px no-repeat;
        width: 16px;
        height: 16px;
    }
    .small-empty-indicator {
        width: 16px;
        height: 16px;
    }
    .uppercase {
        text-transform: uppercase;
    }

    @media (max-width: 768px) {
        .match-table__main-group-stage-title {
            font-size: 0.875rem;
        }
        .match-table__split-column-wrapper {
            display: flex;
            justify-content: space-between;
        }
        .match-table__left-header {
            background-color: #212B30;
            padding: 0.875rem 0 0.875rem 0.75rem;
        }
        .match-table__right-header {
            background-color: #212B30;
            padding: 0.875rem 0.75rem 0.875rem 0;
        }
        .match-table__left-side {
            min-width: 13.75rem;/*11.913rem*/
            max-width: 13.75rem;/*11.913rem*/
            font-weight: 700;
            font-size: 0.875rem;
            padding-bottom: 0.875rem;
            /* padding: 0.875rem 0 0.875rem 0.75rem; */
            box-sizing: border-box;
        }
        .match-table__right-side {
            /* min-width: 28.75rem;
            max-width: 28.75rem; */
            min-width: calc(100% - 13.75rem);
            overflow-x: scroll;
            font-weight: 700;
            font-size: 0.875rem;
            padding-bottom: 0.875rem;
            /* padding: 0.875rem 0.75rem 0.875rem 0; */
        }
        .match-table__split-column-wrapper .match-table {
            min-height: 2.875rem;
            box-sizing: border-box;
            padding: .5rem 0;
        }
        .match-table__left-side .match-table {
            min-width: 13.75rem;
            max-width: 13.75rem;
            padding-left: 0.75rem;
            box-sizing: border-box;
        }
        .match-table__right-side .match-table {
            padding-right: 0.75rem;
        }
        .match-table__split-column-wrapper .match-table::after {
            width: calc(100% - 0.75rem);
        }
        .match-table__right-side .match-table__wc-score {
            width: 16rem;
        }
        .match-table__right-side .match-table__score>div {
            flex: 0;
            min-width: 1.5rem;
        }
        .match-table__right-side .match-table__score-with-last-5 {
            /* min-width: 12rem; */
            /* width: 28rem; */
        }
        .match-table__right-side .last-5-container {
            flex: 0.5 !important;
        }
    }
</style>
